<template>
  <!-- 供水 -->
  <el-dialog
    title="添加供水控制规则"
    :visible.sync="dialogVisible"
    width="800px"
    :close-on-click-modal="false"
    @closed="clearForm"
  >
    <!-- 日期 -->
    <div class="week_box">
      <el-checkbox v-model="weeks.week1">周一</el-checkbox>
      <el-checkbox v-model="weeks.week2">周二</el-checkbox>
      <el-checkbox v-model="weeks.week3">周三</el-checkbox>
      <el-checkbox v-model="weeks.week4">周四</el-checkbox>
      <el-checkbox v-model="weeks.week5">周五</el-checkbox>
      <el-checkbox v-model="weeks.week6">周六</el-checkbox>
      <el-checkbox v-model="weeks.week7">周日</el-checkbox>
    </div>

    <!-- 规则名称 -->
    <div class="rule_name">
      <div class="input_box">
        <div class="label">规则名称</div>
        <input type="text" v-model="ruleName" maxlength="10" />
      </div>
    </div>

    <!-- 产水时间 -->
    <div class="item_box">
      <div class="title">
        <div class="left_box">
          <img src="../../../../../assets/pre_config_1.png" />
          <span>供水时间</span>
          <div class="star">*</div>
        </div>
      </div>
      <div class="content">
        <el-time-picker
          style="flex: 0 0 270px"
          is-range
          v-model="time1"
          range-separator="-"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          format="HH:mm"
          value-format="HH:mm"
        >
        </el-time-picker>
      </div>
    </div>

    <!-- 设置水位 -->
    <div class="item_box">
      <div class="title">
        <div class="left_box">
          <img src="../../../../../assets/pre_config_4.png" />
          <span>设置水位</span>
        </div>
        <div class="right">
          <el-select v-model="index1" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="content">
        <span class="font">最低运行水位</span>
        <el-input
          placeholder="请输入"
          maxlength="3"
          v-model="preset1"
          style="flex: 0 0 88px"
        />
        <span class="font">(％)</span>
      </div>
    </div>

    <!-- 设置温度 -->
    <div class="item_box">
      <div class="title">
        <div class="left_box">
          <img src="../../../../../assets/pre_config_4.png" />
          <span>设置温度</span>
        </div>
        <div class="right">
          <el-select v-model="index2" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="content">
        <span class="font">最低运行温度</span>
        <el-input
          placeholder="请输入"
          maxlength="3"
          v-model="preset2"
          style="flex: 0 0 88px"
        />
        <span class="font">(℃)</span>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="addControl">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { AddChannelTaskApi, GetWaterCase } from "@/api";

export default {
  data() {
    return {
      // 端口信息
      port: {},
      // 项目id
      id_project: "",
      // 设备号
      id_device: "",
      // 是否显示弹窗
      dialogVisible: false,
      weeks: {
        week1: true,
        week2: true,
        week3: true,
        week4: true,
        week5: true,
        week6: true,
        week7: true,
      },
      // 规则名称
      ruleName: "",
      // 时间
      time1: ["", ""],
      // 水位
      preset1: "",
      options: [],
      index1: "",
      // 温度
      preset2: "",
      index2: "",
    };
  },
  methods: {
    // 添加机组控制
    add(port, project) {
      this.port = port;
      this.id_device = port.id_device;
      this.id_project = project;
      this.getWaterCaseList();
      this.dialogVisible = true;
    },

    // 获取水箱
    async getWaterCaseList() {
      const params = {
        id_device: this.id_device,
      };
      const { ret, data, msg } = await GetWaterCase(params);
      if (ret) {
        return this.$message.error(msg);
      }
      this.options = data;
    },

    // 选中项发生变化
    selectedChange(item) {
      this.provideWaterTime.map((i) => {
        if (i.id !== item.id) {
          i.selected = false;
        }
      });
    },

    // 添加机组控制
    async addControl() {
      // 校验星期是否选择了
      let sum = 0;
      const arr = [0, 1, 2, 3, 4, 5, 6];
      arr.map((i) => {
        if (this.weeks["week" + (i + 1)]) {
          sum += Math.pow(2, i);
        }
      });
      if (sum === 0) {
        return this.$message.warning("请选择星期");
      }
      // 校验规则名称是否输入了
      const ruleName = this.ruleName.trim();
      if (!ruleName) {
        this.$message.warning("请输入规则名称");
        return;
      }

      // 供水时间
      if (!this.time1 || !this.time1[0]) {
        return this.$message.warning("请选择供水时间");
      }

      const reg = /(^[1-9]\d{0,1}$)|(^100$)/;
      // 水位 其中一个有值就校验
      if (this.index1 !== "" || this.preset1) {
        if (this.index1 === "") {
          return this.$message.warning("请选择水位");
        }
        if (!reg.test(this.preset1)) {
          return this.$message.warning("请检查水位输入");
        }
      }
      // 温度
      if (this.index2 !== "" || this.preset2) {
        if (this.index2 === "") {
          return this.$message.warning("请选择温度");
        }
        if (!reg.test(this.preset2)) {
          return this.$message.warning("请检查温度输入");
        }
      }

      const params = {
        id_channel_control: this.port.id,
        week_day: sum,
        remark: ruleName,
        time_start: this.time1[0] + ":00",
        time_end: this.time1[1] + ":59",
        detail_array: [],
      };

      const levelValue = parseInt(this.preset1);
      const tempValue = parseInt(this.preset2);
      // 水位
      if (levelValue) {
        params.detail_array.push({
          id: this.index1,
          cfg_type: 1,
          preset: levelValue,
          target: levelValue,
        });
      }
      // 温度
      if (tempValue) {
        params.detail_array.push({
          id: this.index2,
          cfg_type: 0,
          preset: tempValue,
          target: tempValue,
        });
      }
      const { ret, data, msg } = await AddChannelTaskApi(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.$message.success("添加供水泵控制计划成功");
      this.dialogVisible = false;
      const port = { ...this.port };
      port.show = false;
      this.$emit("refresh", port);
    },

    // 清除控制规则
    clearForm() {
      this.ruleName = "";
      this.index1 = "";
      this.index2 = "";
      this.preset1 = "";
      this.preset2 = "";
      this.time1 = ["", ""];
      Object.keys(this.weeks).map((key) => {
        this.weeks[key] = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.week_box {
  text-align: center;
}

.rule_name {
  display: flex;
  justify-content: center;
  margin: 18px 0;

  .input_box {
    width: 300px;
    display: flex;
    align-items: center;

    .label {
      position: relative;
      width: 90px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      text-align: right;
      padding-right: 12px;

      &::before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        content: "*";
        color: red;
        font-size: 14px;
      }
    }

    input {
      flex: 1;
      height: 32px;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      outline: none;
      box-sizing: border-box;
      padding-left: 12px;
    }
  }
}

.item_box {
  border: 1px solid #f0f3f8;
  border-radius: 4px;
  margin: 12px 0;

  .title {
    height: 48px;
    background: #f0f3f8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 12px;

    .left_box {
      display: flex;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
        margin: 0 12px 0 24px;
        transform: translateY(6px);
      }

      span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .star {
        color: #ff0000;
        margin-left: 4px;
        line-height: 1;
      }
    }
  }

  .content {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;

    .font {
      font-family: Source Han Sans, Source Han Sans;
      font-weight: 400;
      font-size: 13px;
      color: #606266;
      margin: 0 12px;
    }
  }
}

.msg {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #f8d071;
  margin-right: 24px;
}
</style>
